var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.error)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('v-sheet',[_c('v-toolbar',{attrs:{"color":"secondary","dark":""}},[_c('v-toolbar-title',[_vm._v("Nutzer")])],1),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":6}},[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":3}},[_vm._v("Id:")]),_c('v-col',[_vm._v(_vm._s(_vm.id))])],1),_c('v-row',[_c('v-col',{attrs:{"cols":3}},[_vm._v("User:")]),_c('v-col',[_vm._v(_vm._s(_vm.user.username))])],1),_c('v-row',[_c('v-col',{attrs:{"cols":3}},[_vm._v("Status:")]),_c('v-col',[_vm._v(_vm._s(_vm.user.active ? "aktiv" : "inaktiv"))])],1)],1)],1)],1),_c('v-col')],1)],1)],1),(_vm.user.current_subscription)?_c('v-sheet',[_c('v-toolbar',{attrs:{"color":"secondary","dark":""}},[_c('v-toolbar-title',[_vm._v("Abo")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('Subscription',{attrs:{"subscription":_vm.user.current_subscription}})],1),(_vm.user.current_subscription)?_c('v-col',{attrs:{"cols":"6"}},[(
            _vm.user.payment &&
            _vm.user.payment.stripe &&
            _vm.user.payment.stripe.subscription
          )?_c('StripePayment',{attrs:{"subscription":_vm.user.payment.stripe.subscription}}):_vm._e(),(
            (!_vm.user.payment || !_vm.user.payment.stripe) &&
            _vm.user.current_subscription.payment_provider === 'stripe'
          )?_c('StripePayment',{attrs:{"subscription":_vm.user.current_subscription}}):_vm._e(),(_vm.user.current_subscription.payment_provider === 'paypal')?_c('PaypalPayment',{attrs:{"subscription":_vm.user.current_subscription}}):_vm._e(),(_vm.user.current_subscription.payment_provider === 'custom')?_c('CustomPayment',{attrs:{"subscription":_vm.user.current_subscription}}):_vm._e()],1):_vm._e()],1)],1):_vm._e(),(!_vm.user.current_subscription)?_c('v-sheet',[_c('v-toolbar',{attrs:{"color":"secondary","dark":""}},[_c('v-toolbar-title',[_vm._v("Custom Abo buchen")])],1),_c('CustomSubscriptionForm',{attrs:{"uid":_vm.id}})],1):_vm._e(),_c('v-divider'),(_vm.user.payment && _vm.user.payment.stripe && _vm.user.payment.stripe.history)?_c('v-row',[_c('v-col',[_c('v-row',{staticClass:"title"},[_vm._v("Abgelaufene Zahlungen")]),_c('v-row',_vm._l((_vm.user.payment.stripe.history),function(item,i){return _c('v-col',{key:i,attrs:{"cols":6}},[_c('StripePayment',{attrs:{"subscription":item}})],1)}),1)],1)],1):_vm._e(),_c('v-sheet',[_c('v-container',[_c('v-card',[_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Rohdaten")]),_c('v-expansion-panel-content',[_c('code',[_vm._v(_vm._s(_vm.jsonUser))])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }