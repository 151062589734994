<template>
  <v-container>
    <!-- <v-text-field v-model="query"></v-text-field> -->
    <v-autocomplete
      v-model="autocomplete_model"
      :items="previous_results"
      :loading="isLoading"
      :search-input.sync="query"
      @keydown="checkSearchEnter"
      color="white"
      hide-no-data
      item-text="query"
      item-value="query"
      placeholder="Suchen"
      prepend-icon="mdi-database-search"
      return-object
    >
      <template v-slot:item="data">
        <v-list-item :to="{ name: 'user-detail', params: { id: data.item.id } }">
          <v-list-item-content>
            <v-list-item-title>{{ data.item.data.username }}</v-list-item-title>
            <v-list-item-subtitle>{{ data.item.id }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
    <v-btn @click="search()">suchen</v-btn>
    <v-alert v-if="no_result" type="warning">"{{ sentquery }}" nicht gefunden</v-alert>
    <v-alert v-if="error" type="error">{{ error }}</v-alert>
    <div v-if="result">
      <v-divider></v-divider>
      <v-subheader>Suchergebnis:</v-subheader>
      <v-list>
        <v-list-item-group>
          <v-list-item :to="{ name: 'user-detail', params: { id: result.id } }">
            <v-list-item-content>
              <v-list-item-title>{{ result.data.username }}</v-list-item-title>
              <v-list-item-subtitle>{{ result.id }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>
    <div v-if="previous_results">
      <v-divider></v-divider>
      <v-subheader>Letzte Suchen:</v-subheader>
      <v-list>
        <v-list-item-group>
          <v-list-item
            v-for="(item, i) in previous_results"
            :key="i"
            :to="{ name: 'user-detail', params: { id: item.id } }"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.data.username }}</v-list-item-title>
              <v-list-item-subtitle>{{ item.id }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>
    <v-overlay :value="search_in_progress">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>Rohdaten</v-expansion-panel-header>
        <v-expansion-panel-content>
          <code>
            {{
            {
            autocomplete_model,
            no_result,
            search_in_progress,
            result,
            previous_results
            }
            }}
          </code>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
/* eslint no-console: ["error", { allow: ["log","warn", "error"] }] */
export default {
  data() {
    return {
      query: "",
      sentquery: "",
      isLoading: false,
      error: null,
      autocomplete_model: {},
    };
  },
  computed: {
    ...mapGetters({
      result: "search/result",
      previous_results: "search/previousResults",
      search_in_progress: "search/searchInProgress",
      no_result: "search/noResult",
      autocompleteResults: "search/previousResultsByQuery",
    }),
    autocomplete() {
      return this.autocompleteResults(this.query);
    },
  },
  methods: {
    search() {
      this.sentquery = this.query;
      this.$store.dispatch("search/search", { query: this.query });
    },
    checkSearchEnter(key) {
      console.log("key:", key);
    },
  },
};
</script>
