<template>
  <div class="home">
    <h1>Home</h1>
    <v-container>
      <v-col>
        <v-card>
          <v-card-title>Suchen</v-card-title>
          <v-card-text>
            <SearchUserForm></SearchUserForm>
          </v-card-text>
        </v-card>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SearchUserForm from "@/components/SearchUserForm";

export default {
  name: "home",
  components: {
    SearchUserForm
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated"
    })
  },
  created: function() {
    this.$store.dispatch("search/resetSearch");
  }
};
</script>
