<template>
  <v-container>
    <v-card>
      <v-card-title>
        <h2 class="title">Custom Payment</h2>
      </v-card-title>
      <v-card-text> </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  props: {
    subscription: Object,
  },
  components: {},
  computed: {
    live() {
      return this.subscription.livemode;
    },

    paypalSubscriptionLink() {
      const id = this.subscription.id;
      //   return "https://www.paypal.com/billing/subscriptions/I-18LXW6M2R30P";
      return "https://www.paypal.com/billing/subscriptions/" + id;
      //   const type = "subscriptions";
      //   return this.paypalLink(id, type);
    },
  },
  created: function () {
    // this.$store.dispatch("setCurrentView", "Login");
  },
  methods: {
    dateFromTimestamp(stamp) {
      return moment.unix(stamp).format("DD.MM.YYYY");
    },
    formatPrice(price) {
      return new Intl.NumberFormat("de-DE").format(price / 100) + " €";
    },
    paypalLink(id, type) {
      // https://www.paypal.com/billing/subscriptions/I-18LXW6M2R30P
      let link = "https://dashboard.stripe.com/";
      if (!this.live) {
        link += "test/";
      }
      link += type + "/" + id;
      return link;
    },

    cancelSubscription() {},
  },
};
</script>
