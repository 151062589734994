<template>
  <v-container>
    <v-alert v-if="error" type="error">{{ error }}</v-alert>
    <v-sheet>
      <v-toolbar color="secondary" dark>
        <v-toolbar-title>Nutzer</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col :cols="6">
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col :cols="3">Id:</v-col>
                  <v-col>{{ id }}</v-col>
                </v-row>
                <v-row>
                  <v-col :cols="3">User:</v-col>
                  <v-col>{{ user.username }}</v-col>
                </v-row>
                <v-row>
                  <v-col :cols="3">Status:</v-col>
                  <v-col>{{ user.active ? "aktiv" : "inaktiv" }}</v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col></v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <v-sheet v-if="user.current_subscription">
      <v-toolbar color="secondary" dark>
        <v-toolbar-title>Abo</v-toolbar-title>
      </v-toolbar>
      <v-row>
        <v-col cols="6">
          <Subscription :subscription="user.current_subscription" />
        </v-col>
        <v-col v-if="user.current_subscription" cols="6">
          <StripePayment
            v-if="
              user.payment &&
              user.payment.stripe &&
              user.payment.stripe.subscription
            "
            :subscription="user.payment.stripe.subscription"
          />
          <StripePayment
            v-if="
              (!user.payment || !user.payment.stripe) &&
              user.current_subscription.payment_provider === 'stripe'
            "
            :subscription="user.current_subscription"
          />
          <PaypalPayment
            v-if="user.current_subscription.payment_provider === 'paypal'"
            :subscription="user.current_subscription"
          />
          <CustomPayment
            v-if="user.current_subscription.payment_provider === 'custom'"
            :subscription="user.current_subscription"
          ></CustomPayment>
        </v-col>
      </v-row>
    </v-sheet>

    <v-sheet v-if="!user.current_subscription">
      <v-toolbar color="secondary" dark>
        <v-toolbar-title>Custom Abo buchen</v-toolbar-title>
      </v-toolbar>
      <CustomSubscriptionForm :uid="id"></CustomSubscriptionForm>
    </v-sheet>
    <v-divider></v-divider>
    <v-row
      v-if="user.payment && user.payment.stripe && user.payment.stripe.history"
    >
      <v-col>
        <v-row class="title">Abgelaufene Zahlungen</v-row>
        <v-row>
          <v-col
            :cols="6"
            v-for="(item, i) in user.payment.stripe.history"
            :key="i"
          >
            <StripePayment :subscription="item" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-sheet>
      <v-container>
        <v-card>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>Rohdaten</v-expansion-panel-header>
              <v-expansion-panel-content>
                <code>{{ jsonUser }}</code>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-container>
    </v-sheet>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import StripePayment from "@/components/StripePayment";
import PaypalPayment from "@/components/PaypalPayment";
import CustomPayment from "@/components/CustomPayment";
import Subscription from "@/components/Subscription";
import CustomSubscriptionForm from "@/components/CustomSubscriptionForm";

export default {
  components: {
    StripePayment,
    PaypalPayment,
    CustomPayment,
    Subscription,
    CustomSubscriptionForm,
  },
  data() {
    return {
      id: this.$route.params.id,
    };
  },
  created: function () {
    this.$store.dispatch("user/fetch", { uid: this.$route.params.id });
  },
  watch: {
    $route(to, from) {
      if (to.params.id != from.params.id) {
        this.id = to.params.id;
        this.$store.dispatch("user/fetch", { uid: to.params.id });
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "user/get",
      error: "user/error",
    }),
    jsonUser() {
      return JSON.parse(JSON.stringify(this.user));
    },
  },
  methods: {
    handleClick() {},
    dateFromTimestamp(stamp) {
      return moment.unix(stamp).format("DD.MM.YYYY");
    },
    formatPrice(price) {
      return new Intl.NumberFormat("de-DE").format(price / 100) + " €";
    },
    stripeLink(id, type, live) {
      // https://dashboard.stripe.com/test/subscriptions/sub_GM2BU5nTwRH4D9
      let link = "https://dashboard.stripe.com/";
      if (!live) {
        link += "test/";
      }
      link += type + "/" + id;
      return link;
    },
  },
};
</script>
