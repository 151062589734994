<template>
  <v-container>
    <v-card>
      <v-card-title>
        <h2 class="title">Basisdaten</h2>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>Plan:</v-col>
          <v-col>
            {{ subscription.product ? subscription.product.name : "" }}
          </v-col>
        </v-row>
        <v-row>
          <v-col>Kosten:</v-col>
          <v-col>{{ formatPrice(subscription.price) }}</v-col>
        </v-row>
        <v-row>
          <v-col>Automatische Verlängerung:</v-col>
          <v-col>
            {{ subscription.cancel_at_period_end ? "deaktiviert" : "aktiv" }}
          </v-col>
        </v-row>
        <v-row>
          <v-col>Ende aktueller Abrechnungszeitraum:</v-col>
          <v-col>
            {{ getDate(subscription.current_period_end) }}
          </v-col>
        </v-row>
        <v-row>
          <v-col>Gekündigt am:</v-col>
          <v-col>
            {{
              subscription.canceled_at === null
                ? "--"
                : getDate(subscription.canceled_at)
            }}
          </v-col>
        </v-row>
        <v-row>
          <v-col>Gekündigt zum:</v-col>
          <v-col>
            {{
              subscription.cancel_at === null
                ? "--"
                : getDate(subscription.cancel_at)
            }}
          </v-col>
        </v-row>

        <v-expansion-panels :flat="true">
          <v-expansion-panel>
            <v-expansion-panel-header style="padding: 0"
              >Rohdaten</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <code>{{ { subscription } }}</code>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
/* eslint no-console: ["error", { allow: ["log","warn", "error"] }] */
import moment from "moment";
export default {
  props: {
    subscription: Object,
  },
  components: {},
  computed: {},
  created: function () {
    // this.$store.dispatch("setCurrentView", "Login");
  },
  methods: {
    getDate(date) {
      if (typeof date === "undefined") {
        return "--";
      }
      if (isNaN(date)) {
        let seconds = date.seconds;
        return this.dateFromTimestamp(seconds);
      }
      return this.dateFromTimestamp(date);
    },
    dateFromTimestamp(stamp) {
      let formated_date = moment.unix(stamp).format("DD.MM.YYYY");
      return formated_date;
    },
    formatPrice(price) {
      return new Intl.NumberFormat("de-DE").format(price / 100) + " €";
    },
  },
};
</script>
